



















import { Vue, Component } from "vue-property-decorator";
import EventParticipants from "@widgets/events/EventParticipants.vue";

@Component({
  name: "notFound",
  components: {
    EventParticipants,
  },
})
export default class notFound extends Vue {}
